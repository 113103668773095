
import { directive } from "vue-awesome-swiper";

export default {
  name: "CarouselArticlesLagi",
  directives: {
    swiper: directive,
  },
  data() {
    return {
      articles: [],
      swiperOption: {
        spaceBetween: 20,
        loop: true,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        slidesOffsetBefore: "6",
        mousewheel: {
          invert: false,
          forceToAxis: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1.3,
          },
          480: {
            slidesPerView: 1.3,
          },
          640: {
            slidesPerView: 1.3,
          },
          1024: {
            slidesPerView: 3.3,
          },
          1536: {
            slidesPerView: 5.3,
          },
        },
      },
    };
  },
  async fetch() {
    this.articles = await this.$content(`articles`)
      .sortBy("date", "desc")
      .limit(10)
      .fetch()
      .catch(() => {
        this.error({ statusCode: 404, message: "no articles found" });
      })
      .finally(
        this.articles.forEach((article, index) => {
          if (
            article.slug ===
            "bagaimanakah-proses-pembuatan-kostum-badut-maskot-boneka-maskot-besar-itu"
          ) {
            this.articles.splice(index, 1);
            this.articles.unshift(article);
          }
        })
      );
  },
};
