
export default {
  name: "CarouselArticle",
  props: {
    article: {
      type: Object,
      default: new Object(),
    },
  },
  data() {
    return {
      dateOptions: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    };
  },
};
