import { render, staticRenderFns } from "./Articles.vue?vue&type=template&id=2c036e74&"
import script from "./Articles.vue?vue&type=script&lang=ts&"
export * from "./Articles.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_mi7kfaeh4nmupiqclubmvzt3ru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CarouselArticle: require('/opt/buildhome/repo/components/Carousel/Article.vue').default})
